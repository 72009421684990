function saveFile(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0)
    }
}

function saveSomeData(string, ext) {
    const d = new Date()
    var mime = "text/plain";
    switch (ext) {
        case ".txt":
            console.log('Tipo txt')
            mime = "text/plain";
            break;
        case ".csv":
            console.log('Tipo csv')
            mime = "text/csv";
            break;
        case ".xls":
            console.log('Tipo xls')
            mime = "application/vnd.ms-excel";
            break;
        case ".xlsx":
            mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            break;
        default:
            break;
    }
    console.log(mime);
    const blob = new Blob([string], {
        type: mime
    });
    saveFile(blob, d.toDateString() + ext)
}


function saveData(string, ext, fileName) {
    const d = new Date()
    const blob = new Blob([string], {
        type: "text/plain"
    });

    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName + ext);
    } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName + ext;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0)
    }
}

// MUNICIPALIDAD DE SAN JOSE INICIO

export const parseMuniSanJose = (dataList, fileName) => {
    // NOTA: REVISAR CEDULAS MENOS DE 9 CARACTERES

    const muniSjNombreParse = (value) => {
        var string = (value + '                              ').substring(0, 30);
        return string;
    }

    console.log(dataList);
    var array = [];
    dataList.forEach(element => {
        var afil = element['type'] === 'AFIL';

        console.log(element.clase);

        if (afil) {
            if (element.clase === 'AFIL') {
                var obj = {
                    line: element.cedula.toString().substring(0, 9) + muniSjNombreParse(element.lastname + " " + element.nombre) + '000000125',
                    // line: element.cedula.toString().substring(0, 9) + muniSjNombreParse(element.lastname + " " + element.nombre) + '000000250',
                }
            } else {
                var obj = {
                    line: element.cedula.toString().substring(0, 9) + muniSjNombreParse(element.lastname + " " + element.nombre) + '000000000',
                }
            }
        } else {
            var obj = {
                line: element.cedula.toString().substring(0, 9) + muniSjNombreParse(element.lastname + " " + element.nombre) + element.rebajo.padStart(7, '0') + '00',
            }
        }

        array.push(obj);
    });
    generateMuniSanJose(array, fileName);
}

const generateMuniSanJose = (input, fileName) => {

    let data = input;
    // let csvContent = "data:text/tsv;charset=utf-8,";
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    // saveSomeData(csvContent, '.txt');
    saveData(csvContent, '.txt', fileName);
}

// MUNICIPALIDAD DE SAN JOSE FINAL

// AYA INICIO

export const parseAya = (dataList, fechaPeriodo, fechaRige, fileName) => {


    console.log(dataList);
    console.log(fechaPeriodo);
    console.log(fechaRige);
    console.log(fileName);

    var dateHasta = new Date(fechaRige);
    // console.log(dateHasta.toLocaleDateString());

    var yearPeriodo = fechaPeriodo.substring(0, 4);
    var monthPeriodo = fechaPeriodo.substring(5, 7);
    var dayPeriodo = fechaPeriodo.substring(8, 10);

    var periodo = yearPeriodo + monthPeriodo + '1';

    var yearRige = fechaRige.substring(0, 4);
    var monthRige = fechaRige.substring(5, 7);
    var dayRige = fechaRige.substring(8, 10);

    var rige = dayRige + '/' + monthRige + '/' + yearRige
    console.log(rige)

    var riged = dayPeriodo + '/' + monthPeriodo + '/' + yearPeriodo

    // 80 caracteres comentarios de la aya
    // POLIZA FECHA ACTIVACION POLIZA
    // AFILIACION FECHA DE AFILIACION


    var array = [];
    dataList.forEach((element, index) => {
        const ayaCedulaParse = (value) => {
            var prefix = (value.toString().length === 9) ? '0' : '';
            var string = (prefix + value + '                              ').slice(0, 30);

            return string;
        }

        var afil = element['type'] === 'AFIL';

        // console.log(element);

        const dateInicioPoliza = new Date(element['inicioPolizaTimestamp']);
        const formattedInicioPoliza = dateInicioPoliza.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        const formattedFechaAfiliacion = element['fechaAfiliacion'].toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });


        if (afil) {
            var ayaObjeto = {
                contador: index + 1,
                pago: "F",
                concepto: '900',
                cedula: ayaCedulaParse(element.cedula).toString(),
                nombre: element.lastname + " " + element.nombre,
                monto: '0000000000',
                periodo: periodo,
                rige_desde: riged,
                rige_hasta: '01/01/2099',
                porcentaje: '01.25',
                // porcentaje: '02.50',
                cantidad: '0',
                limite: '0',
                acumulado: '0',
                formalizacion: formattedFechaAfiliacion,
                comentarios: 'AFILIACION',
                rh: ''
            }
        } else {
            var ayaObjeto = {
                contador: index + 1,
                pago: "P",
                concepto: '870',
                cedula: ayaCedulaParse(element.cedula),
                nombre: element.lastname + " " + element.nombre,
                monto: element['rebajo'] + '00',
                periodo: periodo,
                rige_desde: riged,
                rige_hasta: rige,
                porcentaje: '00.00',
                cantidad: '0',
                limite: '0',
                acumulado: '0',
                formalizacion: formattedInicioPoliza,
                comentarios: 'POLIZA ' + element['displayName'],
                rh: ''
            }
        }


        array.push(
            ayaCedulaParse(element.cedula) +
            ' ' +
            ayaObjeto.concepto +
            ' ' +
            ayaObjeto.pago +
            ' ' +
            ayaObjeto.monto.padStart(10, "0") +
            ' ' +
            '0000000000' +
            ' ' +
            '0000000000' +
            ' ' +
            ayaObjeto.rige_desde +
            ' ' +
            ayaObjeto.rige_hasta +
            ' ' +
            ayaObjeto.porcentaje +
            ' ' +
            ayaObjeto.periodo +
            ' ' +
            '00000.00' +
            ' ' +
            ayaObjeto.comentarios.padEnd(79, " ") +
            ' ' +
            ayaObjeto.formalizacion
        );
        // array.push(ayaObjeto);
    });

    generarAYA(array, fileName);

}

const generarAYA = (input, fileName) => {
    console.log('input');
    console.log(input);

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {

        csvContent += element + "\r\n";

    });

    saveData(csvContent, '.txt', fileName);
}

// AYA FINAL

// CCSS INCIO 

export const parseCcss = (dataList, rigeDesde, rigeHasta, fileName) => {

    var array = [];
    dataList.forEach((element, index) => {

        const ccssCedulaParse = (value) => {
            var string = ('00000000000' + value).slice(-11);
            return string;
        }


        const montoParse = (value) => {
            var string = ('00000000000' + value).slice(-11);
            return string;
        }


        var afil = element['type'] === 'AFIL';


        if (afil) {
            var ccssObjeto = {
                cedula: ccssCedulaParse(element.cedula),
                relleno: 9999,
                codigo: '0618',
                monto: "00000000000",
                decimales: "00",
                rige_desde: '00000000',
                rige_hasta: '99999999',
                numero_deduccion: '0000000000',
                relleno_segundo: "00000000000000000000000000",
                condicion: "1",
                fecha: "00000000"
            }
        } else {
            var ccssObjeto = {
                cedula: ccssCedulaParse(element.cedula),
                relleno: 9999,
                codigo: '0913',
                monto: montoParse(element['rebajo']),
                decimales: "00",
                rige_desde: '00000000',
                rige_hasta: '99999999',
                numero_deduccion: '0000000000',
                relleno_segundo: "00000000000000000000000000",
                condicion: "1",
                fecha: "00000000"
            }
        }

        array.push(ccssObjeto);
    });
    generarCcss(array, fileName);
}




const generarCcss = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString();
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    saveData(csvContent, '.txt', fileName);
}

// CCSS FINAL 

// CONTRALORIA INICIO

export const parseContraloria = (dataList, fileName) => {

    const cedula10Parse = (value) => {
        var string = ('0000000000' + value).slice(-10);
        return string;
    }

    const contraloriaNombreParse = (value) => {
        var string = (value + '                             ').substring(0, 29);
        return string;
    }

    const montoParse = (value) => {
        var string = ('0000000000000000' + value).slice(-16);
        return string;
    }


    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        if (afil) {
            var obj = {
                codigo_constante: '2462000' + ' ' + contraloriaNombreParse(element.lastname + " " + element.nombre) + cedula10Parse(element.cedula) + "000000000000000000",
            }
        } else {
            var obj = {
                codigo_constante: '2910550' + ' ' + contraloriaNombreParse(element.lastname + " " + element.nombre) + cedula10Parse(element.cedula) + montoParse(element['rebajo']) + '00',
            }
        }

        array.push(obj);
    });
    generarContraloria(array, fileName);
}




const generarContraloria = (input, fileName) => {

    let data = input;
    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    saveData(csvContent, '.txt', fileName);
}

// CONTRALORIA FINAL 

// CENCINAI INICIO 

export const parseCenCinai = (dataList, fechaDesdeCinai, fechaHastaCinai, fileName) => {

    // console.log(dataList);

    var year = fechaDesdeCinai.substring(0, 4);
    var month = fechaDesdeCinai.substring(5, 7);
    var day = fechaDesdeCinai.substring(8, 10);

    // var periodo = year + month + day;
    var periodo = day + '/' + month + '/' + year;


    var year = fechaDesdeCinai.substring(0, 4);
    var month = fechaDesdeCinai.substring(5, 7);
    var day = fechaDesdeCinai.substring(8, 10);

    var periodoAnoMes = year + month;

    var yearHasta = fechaHastaCinai.substring(0, 4);
    var monthHasta = fechaHastaCinai.substring(5, 7);
    var dayHasta = fechaHastaCinai.substring(8, 10);

    // var periodoHasta = yearHasta + monthHasta + dayHasta;
    var periodoHasta = dayHasta + '/' + monthHasta + '/' + yearHasta;


    var array = [];
    dataList.forEach((element, index) => {

        const dateInicioPoliza = new Date(element['inicioPolizaTimestamp']);
        const formattedInicioPoliza = dateInicioPoliza.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        const formattedFechaAfiliacion = element['fechaAfiliacion'].toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        var afil = element['type'] === 'AFIL';

        var rebajo = parseInt(element["rebajo"]);

        if (afil) {
            var obj = {
                'Codigo del Socio negocios': "500-0012",
                'Codigo del tipo de Deduccion': "01201",
                'Cedula': element.cedula,
                'Referencia': 'ANEP DEDUCCION PORCENTUAL',
                'Metodo': "0",
                'Valor del porcentaje o monto a rebajar': "1.25",
                // 'Valor del porcentaje o monto a rebajar': "2.5",
                'Monto Total del prestamo': '0',
                'Fecha Inicio Rebajo': periodo,
                'Fecha Finaliza Rebajo': periodoHasta,
                'Saldo': "0",
                'Moneda': "CRC",
                'Fecha formalizacion de la operacion': formattedFechaAfiliacion,
                'Numero de operacion': element.referencia
            }
            // array.push(obj);
        } else {
            var obj = {
                'Codigo del Socio negocios': "500-0012",
                'Codigo del tipo de Deduccion': "01202",
                'Cedula': element.cedula,
                'Referencia': 'POLIZA COLECTIVA DE VIDA ' + element['displayName'],
                'Metodo': "1",
                'Valor del porcentaje o monto a rebajar': rebajo / 2,
                'Monto Total del prestamo': '0',
                'Fecha Inicio Rebajo': periodo,
                'Fecha Finaliza Rebajo': periodoHasta,
                'Saldo': "0",
                'Moneda': "CRC",
                'Fecha formalizacion de la operacion': formattedInicioPoliza,
                'Numero de operacion': element['displayName']
            }
            // array.push(obj);
        }
        array.push(obj);
    });
    generarCenCinai(array, fileName);
}

const generarCenCinai = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + ";";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    saveData(csvContent, '.csv', fileName);
}


// CENCINAI FINAL 

// JPS INICIO

export const parseJPS = (dataList, periodo, fileName) => {
    // NOTA: SOLO MONTH YEAR

    const jpsNombreParse = (value) => {
        var string = (value + '                              ').substring(0, 30);
        return string;
    }

    console.log(periodo);

    var year = periodo.substring(0, 4);
    var month = periodo.substring(5, 7);

    var periodoFormato = year + month;

    const cedula10Parse = (value) => {
        var string = ('0000000000' + value).slice(-10);
        return string;
    }

    const montoParse = (value) => {
        var string = ('000000' + value).slice(-6);
        return string;
    }

    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        if (afil) {
            var jpsObj = {
                line: '0' + element.cedula.toString().substring(0, 9) + jpsNombreParse(element.lastname + " " + element.nombre) + periodoFormato + '00000100',
            }
        } else {
            var jpsObj = {
                line: '0' + element.cedula.toString().substring(0, 9) + jpsNombreParse(element.lastname + " " + element.nombre) + periodoFormato + montoParse(element['rebajo']) + '00',
            }
            console.log(element);
        }


        array.push(jpsObj);
    });
    generarJps(array, fileName);
}




const generarJps = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    saveData(csvContent, '.txt', fileName);
}

// JPS FINAL

// IMAS INICIO

export const parseImas = (dataList, periodoImas, fileName) => {

    var year = periodoImas.substring(0, 4);
    var month = periodoImas.substring(5, 7);
    var day = periodoImas.substring(8, 10);

    var periodoImas = year + month;

    const cedula10Parse = (value) => {
        var string = ('0000000000' + value).slice(-10);
        return string;
    }

    const imasNombreParse = (value) => {
        var string = (value + '                              ').substring(0, 30);
        return string;
    }

    const montoParse = (value) => {
        var string = ('0000000' + value).slice(-7);
        return string;
    }

    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        if (afil) {
            var imasObj = {
                unique: cedula10Parse(element.cedula) + imasNombreParse(element.lastname + ' ' + element.nombre) + periodoImas + "000000000"
            }
        } else {
            var imasObj = {
                unique: cedula10Parse(element.cedula) + imasNombreParse(element.lastname + ' ' + element.nombre) + periodoImas + montoParse(element['rebajo']) + "00"
            }
        }


        array.push(imasObj);
    });
    generarImas(array, fileName);
}




const generarImas = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    saveData(csvContent, '.txt', fileName);
}

// IMAS FINAL 


// ICT INICIO 

export const parseIct = (dataList, fileName) => {

    const cedula10Parse = (value) => {
        var string = ('00000000000' + value).slice(-10);
        return string;
    }

    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        if (afil) {
            var ictObj = {
                cedula: cedula10Parse(element.cedula),
                codigo_deduccion: "612000061",
                couta: "1.10",
                relleno: "0",
                relleno_dos: "0"
            }
        } else {
            var ictObj = {
                cedula: cedula10Parse(element.cedula),
                codigo_deduccion: "612000063",
                couta: element['rebajo'] + ".00",
                relleno: "0",
                relleno_dos: "0"
            }
        }



        array.push(ictObj);
    });
    generarIct(array, fileName);
}




const generarIct = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });
    saveData(csvContent, '.txt', fileName);
}

export const parseIctXls = (dataList, fileName) => {

    const cedula10Parse = (value) => {
        var string = ('00000000000' + value).slice(-10);
        return string;
    }

    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        if (afil) {
            var ictObj = {
                cedula: cedula10Parse(element.cedula),
                nombre: element.lastname + " " + element.nombre,
                couta: "1.10",
            }
        } else {
            var ictObj = {
                cedula: cedula10Parse(element.cedula),
                nombre: element.lastname + " " + element.nombre,
                couta: element['rebajo'] + ".00",
            }
        }



        array.push(ictObj);
    });
    generarIctXls(array, fileName);
}




const generarIctXls = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    saveData(csvContent, '.xls', fileName);
}

// ICT FINAL 

// SINAC INICIO

export const parseSinac = (dataList, fileName) => {

    const cedula10Parse = (value) => {
        var string = ('00000000000' + value).slice(-10);
        return string;
    }

    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        if (afil) {
            var sinacObj = {
                cedula: cedula10Parse(element.cedula),
                codigo_deduccion: "102000169",
                codigo_hacienda: '1.25',
                // codigo_hacienda: '2.5',
                relleno: "0",
                relleno_dos: "0"
            }
        } else {
            var sinacObj = {
                cedula: cedula10Parse(element.cedula),
                codigo_deduccion: "1008000002",
                codigo_hacienda: element['rebajo'] + '.00',
                relleno: "0",
                relleno_dos: "0"
            }
        }



        array.push(sinacObj);
    });
    generarSinac(array, fileName);
}




const generarSinac = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + "\t";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });

    // const d = new Date()
    // var encodedUri = encodeURI(csvContent);
    // var link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "Reporte " + d.toDateString() + ".txt");
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    saveData(csvContent, '.txt', fileName);
}


// SINAC FINAL 

// PANI INICIO

export const parsePani = (dataList, fechaDesdePani, fechaHastaPani, fileName) => {


    var year = fechaDesdePani.substring(0, 4);
    var month = fechaDesdePani.substring(5, 7);
    var day = fechaDesdePani.substring(8, 10);

    var periodo = year + '-' + month + '-' + day;

    var yearHasta = fechaHastaPani.substring(0, 4);
    var monthHasta = fechaHastaPani.substring(5, 7);
    var dayHasta = fechaHastaPani.substring(8, 10);

    var periodoHasta = yearHasta + '-' + monthHasta + '-' + dayHasta;


    var array = [];
    dataList.forEach((element, index) => {

        var afil = element['type'] === 'AFIL';

        var rebajo = parseInt(element["rebajo"]);
        var displayName = element["displayName"];

        // console.log(element);

        if (afil) {
            var obj = {
                'Código del Socio negocios': "3002045185",
                'Código del Tipo de Deducción': "20",
                'Cédula': element.cedula,
                referencia: year + "-" + month + "-185" + "-20",
                metodo: '0',
                monto_deduccion: "1.25",
                // monto_deduccion: "2.5",
                saldo: '0',
                fecha_desde: periodo,
                fecha_actual: periodoHasta,
                saldo: "0",
                moneda: "CRC",
                'Fecha Formalizacion': element.fechaFormalizacion,
                consecutivo: element.referencia
            }
        } else {
            console.log(element)
            var obj = {
                'Código del Socio negocios': "3002045185",
                'Código del Tipo de Deducción': "87",
                'Cédula': element.cedula,
                referencia: year + "-" + month + "-185" + "-20",
                metodo: '1',
                monto_deduccion: rebajo / 2,
                saldo: '0',
                fecha_desde: periodo,
                fecha_actual: periodoHasta,
                saldo: "0",
                moneda: "CRC",
                'Fecha Formalizacion': element.fechaFormalizacion,
                consecutivo: element['numero']
            }
        }



        array.push(obj);
    });
    generarPani(array, fileName);
}

const generarPani = (input, fileName) => {

    let data = input;
    let csvContent = "";

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        if (index === 0) {
            let header = Object.keys(element).join(';');
            csvContent += header + "\r\n";
        }

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + ";";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

    });


    // const d = new Date()
    // var encodedUri = encodeURI(csvContent);
    // var link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "Reporte " + d.toDateString() + ".csv");
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    saveData(csvContent, '.xls', fileName);
}




// PANI FINAL 


// MD INICIO

export const parseMd = (dataList) => {

    const cedula12Parse = (value) => {
        var prefix = (value.toString().length === 9) ? '0' : '';
        var string = (prefix + value);
        return string;
    }

    var array = [];
    dataList.forEach((element, index) => {
        var splitDate = element.fechaAfiliacion.split('/');
        var pasedDate = splitDate[0] + '-' + splitDate[1] + '-' + splitDate[2];

        var type = element['type'] === 'AFIL';

        function getCuota() {
            if (element['type'] === 'AFIL' && element.institucion === 'DIRECCION NACIONAL PENSIONES') {
                return "0,50";
                // return "1";
            } else if (element['type'] === 'AFIL') {
                return "1,25";
                // return "2,50";
            } else {
                return element["rebajo"];
            }
        }

        // var splitDate = element.fechaAfiliacion.split('/');

        // var year = splitDate[2];
        // var month = ("0" + splitDate[1]).slice(-2);
        // var day = ("0" + splitDate[0]).slice(-2);
        // console.log(year, month, day)

        let mdObjeto = {
            cedula: cedula12Parse(element.cedula),
            numerooperacion: type ? "AFIL-001" : element['displayName'],
            codigodeduccion: type ? '102000169' : '1008000002',
            fechaformalizacion: pasedDate,
            cuota: getCuota(), // monto dos cedimales ,00
            montodeuda: '0',
            numerofinca: '0',
            obervaciones: type ? "CUOTA AFILIACION" : "POLIZA DE VIDA ANEP"
        }
        array.push(mdObjeto);
    });
    generarMd(array);
}




const generarMd = (input) => {

    console.log(input);

    let data = input;
    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvContent = "";

    var counter = 0;

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        if (index === 0) {
            let header = Object.keys(element).join(';');
            csvContent += header + "\r\n";
        }

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + ";";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";
        counter = counter + parseFloat(element.cuota.replace(',', '.'));

        console.log(element);

    });

    csvContent += 'totalregistros:' + data.length + "\r\n";
    csvContent += 'totalcuota:' + counter.toString().replace('.', ',');

    saveSomeData(csvContent, '.csv');

    // const d = new Date()
    // var encodedUri = encodeURI(csvContent);
    // var link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "Reporte " + d.toDateString() + ".csv");
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
}

// MD FINAL 


// CD INICIO

export const parseCd = (dataList) => {

    console.log(dataList);
    var array = [];
    dataList.forEach((element, index) => {

        var codigo = element.institucion;
        switch (codigo) {
            case "ASAMBLEA LEGISLATIVA":
                codigo = "100000117";
                break;
            case "DIRECCION GENERAL DE MIGRACION Y EXTRANJERIA":
                codigo = "1202000002";
                break;
            case "DEFENSORIA DE LOS HABITANTES":
                codigo = "100000135";
                break;
            case "DESARROLLO DE LA COMUNIDAD":
                codigo = "1202000001";
                break;
            case "DIRECCION GENERAL DE SERVICIO CIVIL":
                codigo = "803000001";
                break;
            case "DIRECCION NACIONAL PENSIONES":
                codigo = "100000145";
                break;
            case "IMPRENTA NACIONAL":
                codigo = "1202000003";
                break;
            case "JUNTA DE PENSIONES MAGISTERIO NACIONAL":
                codigo = "102000001";
                break;
            case "MINISTERIO DE AGRICULTURA Y GANADERIA":
                codigo = "100000125";
                break;
            case "MINISTERIO DE AMBIENTE Y ENERGIA":
                codigo = "100000143";
                break;
            case "MINISTERIO DE CIENCIA Y TECNOLOGIA":
                codigo = "100000142";
                break;
            case "MINISTERIO DE ECONOMIA COMERCIO EXTERIOR":
                codigo = "100000140";
                break;
            case "MINISTERIO DE CULTURA Y JUVENTUD":
                codigo = "100000132";
                break;
            case "MINISTERIO DE GOBERNACION Y POLICIA":
                codigo = "100000121";
                break;
            case "MINISTERIO DE ECONOMIA INDUSTRIA Y COMERCIO":
                codigo = "100000126";
                break;
            case "MINISTERIO DE EDUCACION PUBLICA":
                codigo = "100000128";
                break;
            case "MINISTERIO DE HACIENDA":
                codigo = "100000124";
                break;
            case "MINISTERIO DE JUSTICIA Y PAZ":
                codigo = "100000133";
                break;
            case "MINISTERIO DE LA PRESIDENCIA":
                codigo = "100000120";
                break;
            case "MINISTERIO DE OBRAS PUBLICAS Y TRANSPORTE":
                codigo = "100000127";
                break;
            case "MINISTERIO DE PLANIFICACION NACIONAL Y POLITICA ECONOMICA":
                codigo = "100000141";
                break;
            case "MINISTERIO DE RELACIONES EXTERIORES Y CULTO":
                codigo = "100000122";
                break;
            case "MINISTERIO DE SALUD":
                codigo = "100000129";
                break;
            case "MINISTERIO DE SEGURIDAD PUBLICA":
                codigo = "100000123";
                break;
            case "MINISTERIO DE TRABAJO Y SEGURIDAD SOCIAL":
                codigo = "100000130";
                break;
            case "MINISTERIO DE VIVIENDA Y ASENTAMIENTOS HUMANOS":
                codigo = "100000137";
                break;
            case "PRESIDENCIA DE LA REPUBLICA":
                codigo = "100000119";
                break;
            case "CONTRALORIA GENERAL DE LA REPUBLICA":
                codigo = "100000147";
                break;
            case "REGISTRO NACIONAL (PRESUPUESTO JUNTA ADMTIVA)":
                codigo = "100000149";
                break;
            case "SERVICIO EXTERIOR":
                codigo = "100000150";
                break;
            case "TRIBUNAL DE SERVICIO CIVIL":
                codigo = "803000002";
                break;
            case "TRIBUNAL SUPREMO ELECCIONES":
                codigo = "100000139";
                break;

            default:
                codigo = "100000124";
        }

        const cedula12Parse = (value) => {
            var prefix = (value.toString().length === 9) ? '0' : '';
            var string = (prefix + value);
            return string;
        }

        var type = element['type'] === 'AFIL';

        function getCuota() {
            if (element['type'] === 'AFIL' && element.institucion === 'DIRECCION NACIONAL PENSIONES') {
                return "0,50";
                // return "1";
            } else if (element['type'] === 'AFIL') {
                return "1,25";
                // return "2,50";
            } else {
                return element["rebajo"];
            }
        }

        var cdObjeto = {
            cedula: cedula12Parse(element.cedula),
            numerooperacion: type ? "AFIL-001" : element['displayName'],
            codigodeduccion: type ? '102000169' : '1008000002',
            // couta: (element.institucion !== 'DIRECCION NACIONAL PENSIONES') ? "1,25" : "0,5",
            cuota: getCuota(),
            quincena: (element.institucion === 'DIRECCION NACIONAL PENSIONES') ? "2" : "0",
            codigoacreedor: "102000069",
            codigoinstitucion: (element.institucion === 'DIRECCION NACIONAL PENSIONES') ? "100000145" : "0"

        }

        array.push(cdObjeto);
    });
    generarCd(array);
}




const generarCd = (input) => {

    let data = input;
    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvContent = "";

    var counter = 0;

    console.log(data)

    data.forEach((element, index) => {
        let longitud = Object.keys(element).length;
        let row = "";

        if (index === 0) {
            let header = Object.keys(element).join(';');
            csvContent += header + "\r\n";
        }

        var i = 0;

        for (const property in element) {
            i++
            if (i !== longitud) {
                row += element[property].toString() + ";";
            } else {
                row += element[property].toString();
            }
        }

        csvContent += row + "\r\n";

        counter = counter + parseFloat(element.cuota.replace(',', '.'));

    });

    csvContent += 'totalregistros:' + data.length + "\r\n";
    csvContent += 'totalcuota:' + counter.toString().replace('.', ',');

    saveSomeData(csvContent, '.csv');
}

// CD FINAL 