import { useEffect, useState } from "react";
import { getFirestore, collection, addDoc, getDocs, query, where, orderBy, limit, startAfter, getDoc, doc, serverTimestamp, Timestamp, deleteDoc } from "firebase/firestore";

const db = getFirestore();


export default function Testing() {
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        // code here

        async function fetchData() {
            // const pagosRef = collection(db, "pagos");
            // const start = Timestamp.fromDate(new Date(2024, 10, 30)); // November 25, 2024
            // const end = Timestamp.fromDate(new Date(2024, 10, 31)); // December 12, 2024
            // const q = query(pagosRef, where("emitido", ">=", start), where("emitido", "<=", end));

            // const querySnapshot = await getDocs(q);

            // const pagos = [];
            // querySnapshot.forEach((doc) => {
            //     var obj = {
            //         doc: doc.id,
            //         emitido: new Date(doc.data().emitido.seconds * 1000).toLocaleDateString(),
            //         id: doc.data().id,
            //         monto: doc.data().monto,
            //         vigencia: new Date(doc.data().vigencia.seconds * 1000).toLocaleDateString(),
            //         periodo: doc.id.slice(-10)
            //     }
            //     pagos.push(obj);
            // });
            // console.log(pagos);
            // setDataList(pagos);
        }

        fetchData();

    }, []);

    // const deleteData = async () => {
    //     try {
    //         for (const document of dataList) {
    //             console.log("Deleting...", document.doc);
    //             await deleteDoc(doc(db, "pagos", document.doc));
    //             console.log("Document successfully deleted!");
    //         }
    //         console.log("All documents successfully deleted!");
    //     } catch (error) {
    //         console.error("Error removing document: ", error);

    //     }
    // }

    return (
        <div>
            <h1>Testing</h1>
            {/* <button className="filled-button" onClick={deleteData}>Delete</button> */}
        </div>
    );
}